import React from "react"
import { Link } from "gatsby"
import Modal from "react-modal"

import { rhythm, scale } from "../utils/typography"
import translate from "../utils/translation"

const navStyles = {
  overlay: {
    background: 'transparent'
  },
  content: {
    top: 0,
    right: 0,
    left: 'auto',
    height: '100vh',
    padding: 0,
    background: '#454545',
    border: 'none',
    borderRadius: 0,
    overflow: 'scroll'
  }
}

Modal.setAppElement('#___gatsby')

class Layout extends React.Component {

  scrolled = false;

  constructor(props) {
    super(props)
    this.state = {
      navIsOpen: false,
      navBarActiveClass: '',
      scrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', event => this.onScrolled(), true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.onScrolled())
  }

  onScrolled() {
    if (window.scrollY > 96 && !this.scrolled) {
      this.scrolled = true
      this.setState(
        {
          scrolled: true
        },
        () => this.scrolled = true
      )
    } else if (window.scrollY <= 96 && this.scrolled) {
      this.scrolled = false
      this.setState(
        {
          scrolled: false
        },
        () => this.scrolled = false
      )
    }
  }

  toggleNav = () => {
    this.setState(
      {
        navIsOpen: !this.state.navIsOpen,
      },
      () => {
        this.state.navIsOpen
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  closeNav = () => {
    this.setState(
      {
        navIsOpen: false,
      },
      () => {
        this.state.navIsOpen
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    const { location, title, children, subTitle, slug, isReverse, world, hasLanguage, worlds } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    let menu
    let lang
    let langPath
    let navClass = ''
    const titleClass = isReverse ? 'reverse' : ''

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
      menu = (
        <div className="navbar-end"></div>
      )
    } else {
      lang = slug ? slug.split('/')[1] : 'ja'
      langPath = `/${lang === 'ja' ? 'en' : 'ja'}/${slug ? slug.substring(4) : ''}`
      if (slug === `/${lang}/`) {
        header = (
          <div className={`header-title ${titleClass}`}>
          </div>
        )
        navClass = 'is-index root'
      } else {
        header = (
          <div className={`header-title ${titleClass}`}>
            <h2>
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}
                to={`/${lang}/`}
              >
                {title}
              </Link>
            </h2>
            <h3>{subTitle}</h3>
          </div>
        )
        navClass = worlds ? 'is-index' : world ? 'world' : ''
      }
      menu = (
        <div className="navbar-end">
          {
            slug !== `/${lang}/` && this.state.navIsOpen && (
              <div className="navbar-item language">
                <Link to={`/${lang}`}
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `#ffffff`,
                  }}>
                  Top
                  </Link>
              </div>
            )
          }
          {
            hasLanguage && this.state.navIsOpen && (
              <div className="navbar-item language">
                <Link to={langPath}
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `#ffffff`,
                  }}>
                  {lang === 'ja' ? `English` : `Japanese`}
                </Link>
              </div>
            )
          }
          <div className={`navbar-burger burger ${this.state.navBarActiveClass}`} onClick={() => this.toggleNav()}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
      >
        <header className={`navbar is-fixed-top is-transparent global-nav ${this.state.navBarActiveClass} ${navClass} ${this.state.scrolled ? 'scrolled' : ''}`}
          role="navigation"
          aria-label="main-navigation">
          <div className="navbar-brand">
            <div className="navbar-item">
              {header}
            </div>
            {menu}
          </div>
        </header>
        <div id="main">{children}</div>
        <Modal
          isOpen={this.state.navIsOpen}
          onRequestClose={this.closeNav}
          style={navStyles}
          contentLabel="Nav"
        >
          <div className="app-nav">
            <h2><a href={`/${lang}/worlds/`} title={translate('worlds.index', lang)}>{translate('worlds.index', lang)}</a></h2>
            <h3>{translate('worlds.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/analysis/`} title={translate('analysis.index', lang)}>{translate('analysis.index', lang)}</a></h2>
            <h3>{translate('analysis.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/about/butoh-fu/`} title={translate('about.butoh-fu', lang)}>{translate('about.butoh-fu', lang)}</a></h2>
            <h3>{translate('about.butoh-fu', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/consideration/`} title={translate('consideration.index', lang)}>{translate('consideration.index', lang)}</a></h2>
            <h3>{translate('consideration.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/list/`} title={translate('list.index', lang)}>{translate('list.index', lang)}</a></h2>
            <h3>{translate('list.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <hr></hr>
            <h2><a href={`/${lang}/archives/`} title={translate('archives.index', lang)}>{translate('archives.index', lang)}</a></h2>
            <h3>{translate('archives.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/about/site`} title={translate('about.site', lang)}>{translate('about.site', lang)}</a></h2>
            <h3>{translate('about.site', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/donation/`} title={translate('donation.index', lang)}>{translate('donation.index', lang)}</a></h2>
            <h3>{translate('donation.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <h2><a href={`/${lang}/appendix/`} title={translate('appendix.index', lang)}>{translate('appendix.index', lang)}</a></h2>
            <h3>{translate('appendix.index', lang === 'ja' ? 'en' : 'ja')}</h3>
            <hr></hr>
            <div className="logo">
              <a href="https://cyzo.co.jp/" target="_blank" title="Patronage: Cyzo">
                <img src="/images/logo/cyzo_logo_w.png" alt="cyzo"></img>
              </a>
            </div>
            <div className="logo">
              <a href="http://www.art-c.keio.ac.jp/" target="_blank" title="Cooperation: Keio University Art Center">
                <img src="/images/logo/kuac_logo_w.png" alt="Keio University Art Center"></img>
              </a>
            </div>
            <h4>{translate('nav.copyright', lang)}</h4>
          </div>
        </Modal>
      </div>
    )
  }
}

Layout.defaultProps = {
  hasLanguage: true
}

export default Layout
