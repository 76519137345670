const texts = {
  'site.name.ja': '舞踏花伝',
  'site.description.ja': '和栗由紀夫',
  'worlds.index.ja': '舞踏譜 7つの世界',
  'world.virtual-stage.ja': '仮想舞台',
  'world.related-movies.ja': '仮想舞台・関連動画',
  'about.butoh-fu.ja': '舞踏譜について',
  'about.butoh-kaden.ja': '舞踏花伝とは',
  'about.site.ja': 'このサイトについて',
  'analysis.index.ja': '舞踏譜俯瞰図（解説映像）',
  'consideration.index.ja': '舞踏譜考察',
  'list.index.ja': '舞踏譜 全索引',
  'list.search.ja': '検索',
  'archives.index.ja': '和栗由紀夫 年譜',
  'donation.index.ja': '寄附のお願い（和栗由紀夫との約束）',
  'appendix.index.ja': '付録',
  'nav.copyright.ja': '© 2020 舞踏花伝web版製作委員会',
  'notation.notation.ja': '舞踏譜',
  'notation.moreabout.ja': '解説・関連譜',
  'notation.workshop.ja': '稽古',
  'notation.explanation.ja': '解説',
  'notation.relation.ja': '関連舞踏譜',
  'donation.go.ja': '寄附のお申し込み',
  'site.name.en': 'Butoh-Kaden',
  'site.description.en': 'Yukio Waguri',
  'worlds.index.en': '7 Worlds of Butho-fu',
  'world.virtual-stage.en': 'Virtual Stage',
  'world.related-movies.en': 'Related Movies',
  'about.butoh-fu.en': 'Introduction About Butoh-fu',
  'about.butoh-kaden.en': 'About Butoh-Kaden',
  'about.site.en': 'About',
  'analysis.index.en': 'Butoh-fu Title Map',
  'consideration.index.en': 'Reflections on Butoh-fu',
  'list.index.en': 'Butoh-fu Indexes',
  'list.search.en': 'Search',
  'archives.index.en': 'Yukio Waguri Chronology',
  'donation.index.en': 'Donation Page (Our Promise with Yukio Waguri)',
  'appendix.index.en': 'Appendix',
  'nav.copyright.en': '© 2020 The Butoh-Kaden Website Committee',
  'notation.notation.en': 'Butoh-fu',
  'notation.moreabout.en': 'More about',
  'notation.workshop.en': 'Workshop',
  'notation.explanation.en': 'Explanation',
  'notation.relation.en': 'Related Butho-fu',
  'donation.go.en': 'Application for Donation',
}

export default function translate(key, lang) {
  return texts[key + '.' + lang]
}
